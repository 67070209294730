import Swiper from 'swiper/dist/js/swiper'

const contactsSlider = () => {
  const wrapper = document.querySelector('.js-contacts-slider-wrapper');
  const sliderEl = wrapper.querySelector('.js-contacts-slider');
  const slides = Array.prototype.slice.call(sliderEl.querySelectorAll('.swiper-slide'));
  const slidesLength = slides.length;
  const pagination = wrapper.querySelector('.swiper-pagination');

  return new Swiper(sliderEl, {
    threshold: 6,
    slidesPerView: 'auto',
    centeredSlides: true,
    loopedSlides: slidesLength,
    loop: true,
    lazy: {
      loadPrevNext: true,
    },
    spaceBetween: 12,
    pagination: {
      el: pagination,
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      599: {
        spaceBetween: 8,
        centeredSlides: true,
      }
    },
  });

};
export default contactsSlider;
