class ContactMap {
  constructor(config) {
    this.elem = $(config);
    this.markers = [];
    this.infopopups = [];
    this.markerLinks = [];
    this.init();
  }

  init() {
    this.initBlock = this.elem.find('.js-map-init');
    this.tabs = this.elem.find('.js-contact-tab');
    this.tabsContent = this.elem.find('.js-contact-content');
    this.zoom = parseInt(this.initBlock.attr('data-zoom'), 10);
    this.icon = this.initBlock.attr('data-pin-src');
    this.latInit = parseFloat(this.elem.find('.js-contact-tab.is-active').attr('data-lat'));
    this.lngInit = parseFloat(this.elem.find('.js-contact-tab.is-active').attr('data-lng'));
    window.onload = () => this.loadMapsAPI();
    this.tabs.on('click', this.tabsHandler.bind(this));
  }

  static createScript(url, callback) {
    const script = document.createElement('script');
    if (callback) script.onload = callback;
    script.type = 'text/javascript';
    script.src = url;
    document.body.appendChild(script);
  }

  loadMapsAPI() {
    const mapKey = this.elem.find('.js-map-init').attr('data-map-key');
    ContactMap.createScript(`https://maps.googleapis.com/maps/api/js?key=${mapKey}`, this.initMap.bind(this));
  }

  initMap() {
    const { google } = window;

    const markerIcon = {
      url: this.icon,
      scaledSize: new google.maps.Size(34, 42),
      origin: new google.maps.Point(0, 0),
    };

    this.map = new google.maps.Map(this.initBlock[0], {
      zoom: this.zoom,
    });

    this.tabs.each((index, tab) => {
      const $tab = $(tab);
      const lat = parseFloat($tab.attr('data-lat'));
      const lng = parseFloat($tab.attr('data-lng'));
      const markerContent = $tab.attr('data-marker-content');
      const markerLink = $tab.attr('data-marker-link');

      const marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        icon: markerIcon,
      });

      this.markers.push(marker);

      const infowindow = new google.maps.InfoWindow({
        content: `<p>${markerContent}</p>`,
      });
      this.infopopups.push(infowindow);

      this.markerLinks.push(markerLink);
    });

    this.map.setCenter({ lat: this.latInit, lng: this.lngInit });
    this.markersClickHandler();
    this.markersHoverHandler();
    this.disableLoader();

    this.tabsInit();
  }

  tabsHandler(e) {
    const $tab = $(e.currentTarget);
    const city = $tab.attr('data-city').toLowerCase();
    this.activateTab(city);
  }

  activateTab(city) {
    const $tab = $(`.js-contact-tab[data-city="${city}"]`);
    this.tabs.removeClass('is-active');
    this.tabsContent.removeClass('is-active');
    const lat = parseFloat($tab.attr('data-lat'));
    const lng = parseFloat($tab.attr('data-lng'));
    $tab.addClass('is-active');
    $(`.js-contact-content[data-city="${city}"]`).addClass('is-active');
    this.map.setCenter({ lat, lng });
  }

  tabsInit() {
    if (window.location.hash) {
      this.activateTab(window.location.hash.substring(1).toLowerCase())
    }
  }

  markersClickHandler() {
    const { google } = window;
    this.markers.forEach((marker, i) => {
      google.maps.event.addListener(marker, 'click', () => {
        const externalMapLink = document.createElement("a");
        externalMapLink.target = '_blank';
        externalMapLink.href = this.markerLinks[i];
        externalMapLink.click();
        externalMapLink.remove();
      });
    });
  }

  markersHoverHandler() {
    this.markers.forEach((marker, i) => {
      marker.addListener('mouseover', () => {
        this.infopopups[i].open(this.map, marker);
      });
      marker.addListener('mouseout', () => {
        this.infopopups[i].close();
      });
    });
  }

  disableLoader() {
    this.elem.find('.js-lazy-map').hide();
  }
}

// eslint-disable-next-line func-names
$.fn.maps = function () {
  return this.each((i, el) => new ContactMap($(el)));
};


$('.js-contacts-maps').maps();
