import './modules/_contacts-maps';
import contactPersonSlider from './sliders/contactPersonSlider/contactPersonSlider';
import contactsSlider from './sliders/contactsSlider/contactsSlider';

document.addEventListener('DOMContentLoaded', () => {
  contactsSlider();

  const contactPersonSliders = Array.prototype.slice.call(document.querySelectorAll('.js-contact-person-slider-wrapper'));
  if (contactPersonSliders.length) {
    contactPersonSliders.forEach((el) => {
      contactPersonSlider(el);
    });
  }

  const $contactTabs = $('.js-contact-form-tab');
  $contactTabs.on('click', (e) => {
    const $tab = $(e.currentTarget);
    const $input = $('.js-contact-type');
    const type = $tab.attr('data-type');
    $input.val(type);
    $contactTabs.removeClass('is-active');
    $tab.addClass('is-active');
  });
});
