import Swiper from 'swiper/dist/js/swiper'

const contactPersonSlider = (wrapper) => {
  if (!wrapper) return;

  const contactPersonSliderEl = wrapper.querySelector('.js-contact-person-slider');
  const $pagination = wrapper.querySelector('.swiper-pagination');

  const contactPersonSliderSwiper = new Swiper(contactPersonSliderEl, {
    init: false,
    slidesPerColumn: 1,
    slidesPerView: 3,
    threshold: 6,
    autoHeight: true,
    pagination: {
      el: $pagination,
      type: 'bullets',
      clickable: true,
      renderBullet(index, className) {
        return `<span class="${className}">${index + 1}</span>`;
      }
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      599: {
        slidesPerView: 1,
      },
      900: {
        slidesPerView: 2,
      }
    },
  });

  contactPersonSliderSwiper.init();

};

export default contactPersonSlider;
